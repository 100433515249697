@import '../Shared/styles/Color.module.scss';
@import '../Shared/styles/Font.module.scss';

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  margin: 41vh auto;
  border-radius: 5px;
  background: $hil-slighty-blue;
  box-shadow: inset 0 0 1px 0 #000;
}

.modalTitle {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  vertical-align: middle;
  font-size: $hil-font17;
  font-weight: 600;
}

.modalContent {
  width: 100%;
  min-height: 45px;
  padding: 0 16px 16px 16px;
  text-align: center;
  vertical-align: middle;
  font-size: $hil-font13;
  font-weight: 400;
  word-wrap: break-word;
}

.modalOperator {
  width: 100%;
  height: 44px;
  border-top: 1px solid $hil-grey;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.modalOperatorClose,
.modalOperatorConfirm {
  width: calc(100% - 2px);
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  opacity: 1;
  border-radius: 0 0 5px 5px;
  font-size: $hil-font17;
  font-weight: 500;
  color: $hil-tint;
  background-color: $hil-slighty-blue;
  cursor: pointer;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.modalOperatorClose {
  border-right: 1px solid $hil-grey;
}

.modalOperatorClose:active,
.modalOperatorConfirm:active {
  opacity: 0.6;
  transition: opacity 0.3s;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $hil-black;
  opacity: 30%;
  z-index: 9998;
}
