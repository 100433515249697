$hil-dark-blue: rgb(16, 76, 151); //#104c97
$hil-light-blue: rgb(0, 156, 222); //#009cde
$hil-light-blue-E3: #e3f0ff;
$hil-slighty-blue: #f5f7fc;
$hil-pure-white: #ffffff;
$hil-grey: #e6e6e6;
$hil-black: #000000;
$hil-light-grey: rgba(192, 192, 192, 0.5);
$hil-slightly-grey: #666666;
$hil-slightler-grey: #dddddd;
$hil-dark-grey: #999999;
$hil-light-black: #1d1d1d;
$hil-black-444: #444444;
$hil-black-03: #030303;
$hil-black-1D: #1d1d1d;
$hil-black-111: #111111;
$hil-grey-AAA: #aaaaaa;
$hil-grey-EEE: #eeeeee;
$hil-error: #ce3262;
$hil-tint: #007aff;
$hil-green: #74d175;
$hil-placeholder: #686d8b;
$hil-purple-B4B: #b4b9d7;
