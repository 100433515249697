@import '../Shared/styles/Color.module.scss';

.HILButton {
  height: 40px;
  width: 100%;
  background-color: $hil-dark-blue;
  font-weight: 600;
  font-size: 16px;
  color: $hil-pure-white;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin-bottom: 15px;
}

.HILButton:disabled,
.HILButton[disabled='disabled'] {
  background-color: $hil-dark-grey;
  pointer-events: none;
}
