/* Fonts */
$hil-font24: 24px;
$hil-font20: 20px;
$hil-font18: 18px;
$hil-font15: 15px;
$hil-font16: 16px;
$hil-font17: 17px;
$hil-font13: 13px;
$hil-font12: 12px;
$hil-font14: 14px;
$hil-font10: 10px;
$hil-font-weight-normal: normal;
$hil-font-weight-medium: 500;
$hil-font-weight-bold: bold;
