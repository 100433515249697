@import '../Shared/styles/Color.module.scss';
@import '../Shared/styles/Font.module.scss';

.hilField {
  background: $hil-slighty-blue;
  border-radius: 4px;
  padding: 12px 15px 12px 20px;
  display: flex;
  font-size: $hil-font12;
  line-height: 22px;
  align-items: center;
  margin-bottom: 15px;

  ::placeholder {
    color: $hil-placeholder;
  }

  input {
    width: 100%;
    border: 0;
    outline: 0;
    background: $hil-slighty-blue;
  }

  input:focus {
    outline: none !important;
  }
  button:disabled,
  button[disabled='disabled'] {
    color: $hil-slightly-grey;
  }

  button {
    all: unset;
    cursor: pointer;
    width: 130px;
    padding: 0 0 0 15px;
    border-left: 0.5px solid $hil-purple-B4B;
    color: $hil-placeholder;
  }

  button:disabled,
  button[disabled='disabled'] {
    pointer-events: none;
  }
}

.hilFieldWithIcon {
  width: calc(100% - 20px);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: $hil-black-111;
}

.hasError {
  color: $hil-error !important;
}
.hasError::placeholder {
  color: $hil-error !important;
}

.togglePassword {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.72rem;
  height: 100%;
  img {
    width: 24px;
    height: 100%;
    vertical-align: middle;
  }
}

.hilFieldSendButton {
  color: $hil-dark-blue !important;
}
.hilFieldSendButton:disabled,
.hilFieldSendButton[disabled='disabled'] {
  color: $hil-placeholder !important;
}
