@import '../Shared/styles/Color.module.scss';

.SmsCode6 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 0 0 0 10px;

  .cell {
    width: 35px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    caret-color: transparent;
    border-radius: 5px;
    border: 1px solid $hil-grey-AAA;
    font-weight: 500;
    font-size: 17px;
    color: $hil-light-black;
  }

  .icon {
    position: relative;
    display: flex;
    width: 18px;
    height: 49px;
    align-items: center;
  }

  .clearIcon {
    width: 18px;
    height: 18px;
  }

  input:focus {
    outline: none;
  }

  // remove tel type input arrows
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='tel'] {
    -moz-appearance: textfield;
  }
}

.hasError {
  input {
    color: $hil-error !important;
  }
}
