/* Layout */

/* Fonts */
$font30: 30px;
$font24: 24px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font12: 12px;
$font10: 10px;
$font14: 14px;
$font-bigger: 16px;
$font-big: 14px;
$font-normal: 12px;
$font-small: 10px;

/* Colors Palette */
$dark-blue: rgb(16, 76, 151); //#104c97
$white: rgb(244, 244, 244); //#f4f4f4
$dark-pink: rgb(206, 50, 98); //#ce3262
$broenish-grey: rgb(102, 102, 102); //#666666
$dusk: rgb(70, 86, 117); //#475675
$squash: rgb(245, 166, 35); //#f5a623
$cerulean: rgb(0, 156, 222); //#009cde
$sky-blue: rgb(97, 208, 255); //#61d0ff
$light-pink: rgb(240, 224, 229); //#f0e0e5
$black: rgb(3, 3, 3); //#030303;
$deep-sky-blue: rgb(0, 122, 255); //007aff
$red: rgb(230, 13, 13); //#e60d0d

/* Customized Color */
$more-dark-grey: rgb(170, 170, 170); //#aaaaaa
$dark-grey: rgb(204, 204, 204);
$grey: rgb(230, 230, 230); //#e6e6e6
$light-grey: rgb(244, 244, 244); //#f4f4f4
$light-blue: rgb(0, 156, 222); //#009cde
$dark-blue: rgb(16, 76, 151); //#104c97
$light-dark: rgb(102, 102, 102); // #666
$dark: rgb(29, 29, 29);
$green: #09bb07;
$error-red: rgb(206, 50, 98); //#ce3262
$campaign-blue: #224d92;
$campaign-red: #be4163;
$silver: #cccccc;
$mine-shaft: #222222;
$flamingo: #ec5d2a;
$drift-wood: #b3874e;
$down-river: #09194e;
$well-read: #ac2e38;
$blue-bayoux: #4d5d70;
$lucky-point: #16216a;
