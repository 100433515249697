@import '../Shared/styles/Color.module.scss';
@import '../Shared/styles/Variables.module.scss';

.modal {
  position: fixed;
  width: 290px;
  height: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  z-index: 9999;

  background: #000;
  opacity: 74%;
  border-radius: 5px;
  box-shadow: inset 0 0 1px 0 #000;

  text-align: center;
  padding: 5px 5px;
  color: #fff;
  font-size: $font14;
  font-weight: 400;
  line-height: 20px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 100%;
  z-index: 9998;
}
