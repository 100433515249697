@import '../Shared/styles/Variables.module.scss';
@import '../Shared/styles/Color.module.scss';
@import '../Shared/styles/Font.module.scss';

.loginContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loginTitle {
  width: 95%;
  margin: 0 auto 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &Font {
    height: 22px;
    line-height: 22px;
    font-size: $font20;
    font-weight: 400;
    color: $hil-light-black;
  }
  &Logo {
    width: 80px;
    height: 30px;
    vertical-align: middle;
    margin-bottom: 20px;
  }
}

.loginPwdBack {
  width: 100%;
  margin: 0 0 25px 0;
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  justify-content: space-between;

  span {
    color: $hil-dark-blue;
    font-size: $font12;
    font-weight: 400;
  }
}

.loginEnrollMessage {
  width: 100%;
  margin-bottom: 10px;
  font-size: $font12;
  font-weight: 400;
  color: $hil-slightly-grey;
}

.tips {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  font-size: $font12;
  flex-wrap: wrap;
  color: $hil-slightly-grey;

  span {
    vertical-align: middle;
  }

  &Terms {
    color: $dark-blue;
  }

  &First {
    margin-left: 3px;
  }

  &Error {
    @extend .tips;
    width: 92%;
    color: $hil-error;
    margin-bottom: 15px;
  }
}

.round {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
}

.round label {
  border: 1px solid $hil-dark-blue;
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}

.round label:after {
  border: 1px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 2.5px;
  left: 1.5px;
  opacity: 0;
  position: absolute;
  top: 2.5px;
  transform: rotate(-45deg);
  width: 6px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: $hil-dark-blue;
  border-color: $hil-dark-blue;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.validationContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $hil-slightly-grey;
  opacity: 100%;
  z-index: 8998;
}

.validatingUI {
  position: fixed;
  width: 100%;
  min-height: 400px;
  top: auto;
  bottom: 0;
  margin: auto;
  padding: 24px 24px;
  border-radius: 15px 15px 0 0;
  background-color: $hil-pure-white;
  overflow: hidden;
  z-index: 8999;

  .tips {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 12px;
    color: $hil-slightly-grey;

    &Error {
      @extend .tips;
      width: 92%;
      height: 40px;
      color: $hil-error;
      align-items: flex-start;
    }
  }
}

.validatingTitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &Icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
  .placeholderRight {
    width: 24px;
  }
  span {
    height: 36px;
    line-height: 36px;
    font-weight: 500;
    font-size: 20px;
    color: $hil-light-black;
  }
}

.bindingUI {
  .bindingTitle {
    font-size: $hil-font18;
    font-weight: 500;
    color: $hil-black;
    line-height: 26px;
  }
  .bindingMessage {
    span {
      font-size: $hil-font15;
      font-weight: 400;
      color: $hil-slightly-grey;
      line-height: 23px;
      span {
        font-weight: 500;
        color: $hil-dark-blue;
      }
    }
  }
  .resend {
    all: unset;
    cursor: pointer;
    width: 100%;
    height: 23px;
    line-height: 23px;
    font-weight: 400;
    font-size: 16px;
    color: $hil-dark-blue;

    &Disabled {
      color: $hil-grey-AAA;
    }

    button:disabled,
    button[disabled='disabled'] {
      pointer-events: none;
    }
  }
}
